/* eslint-disable no-undef */
import './index.scss';

import React, { Component } from 'react';
import { withRouter } from 'react-router';

import AccountSideMenuProfile from '../../components/account-sidemenu-profile';
import AccountSideMenuList from '../../components/account-sidemenu-list';
// import ReferAndEarn from '../../components/refer-and-earn';

import { ROUTES as _routes } from '../../constants/app-constants';
// import { SessionStorage } from '../../burger-king-lib/utils/storage';
// import { LocalSessionKey } from '../../burger-king-lib/constants/app-constants';

import {
  getCloseIcon,
  getRightArrowImage,
  getRestaurantFinderImage,
  getSettingsIcon,
  getLeftArrowImage,
  getNotificationImage
} from '../../utils/imageChanges';

// import CrownImage from '../../../assets/images/offers/crown.png';
// import PlayStoreIcon from '../../../assets/images/play-store-icon.png';

class AccountSideMenu extends Component {
  constructor(props) {
    super(props);
    this.state = {
      menuListActivekey: '',
      userData: {},
      showSettingsList: props?.showSettingsList || false
    };
  }

  componentDidMount() {
    const { location, userData } = this.props;
    const { pathname } = location;
    this.setInitialProgress(pathname);
    if (userData && userData.userDetail && userData.userDetail.content) {
      this.setUserData(userData.userDetail.content);
    }
  }

  componentDidUpdate(prevProps, prevState) {
    const { location, userData } = this.props;
    if (prevProps.location !== location) {
      const { pathname } = location;
      this.setInitialProgress(pathname);
    }
    if (
      userData &&
      userData.userDetail &&
      userData.userDetail !== prevProps.userData.userDetail &&
      userData.userDetail.content
    ) {
      this.setUserData(userData.userDetail.content);
    }
  }

  setUserData = data => {
    const [FirEl] = data;
    this.setState({ userData: FirEl });
  };

  setInitialProgress = path => {
    let pathSplit = path.split(`${_routes.ACCOUNT}/`);
    if (pathSplit && pathSplit.length) {
      if (pathSplit[1]) {
        pathSplit = pathSplit[1];
        this.setState({ menuListActivekey: this.hasSettingMenu(pathSplit) });
      } else this.setState({ menuListActivekey: '' });
    }
  };

  hasSettingMenu = value => {
    switch (value) {
      case 'terms-conditions':
      case 'privacy-policy':
      case 'promotional-terms':
      case 'notification-settings':
      case 'help-support':
        this.setState({
          showSettingsList: window.innerWidth < 768 ? false : true,
          activeSettingsList: value
        });
        value = 'settings';
        break;
      default:
        break;
    }
    return value;
  };

  CloseNav = () => {
    const { hideHamburgerMenu } = this.props;
    hideHamburgerMenu();
  };

  recentOrderClick = () => {
    if (window.innerWidth >= 1024) {
      const { history } = this.props;
      history.push(_routes.RECENT_ORDERS);
    } else {
      this.CloseNav();
      // this.menuOnClick('recent-orders');
      const { history } = this.props;
      history.push(_routes.RECENT_ORDERS);
    }
    // this.setState({menuListActiveIndex : 0})
  };

  savedAddressesClick = () => {
    if (window.innerWidth >= 1024) {
      this.menuOnClick('saved-addressess');
    } else {
      this.CloseNav();
    }
    const { history } = this.props;
    history.push(_routes.SAVED_ADDRESSES);
  };

  notificationClick = () => {
    if (window.innerWidth >= 1024) {
      this.menuOnClick('notifications');
    } else {
      this.CloseNav();
    }
    const { history } = this.props;
    history.push(_routes.NOTIFICATIONS);
  };

  settingsClick = () => {
    if (window.innerWidth >= 1024) {
      const { showSettingsList } = this.state;
      this.setState({
        showSettingsList: !showSettingsList,
        menuListActivekey: 'settings'
      });
    } else {
      this.CloseNav();
      const { history } = this.props;
      history.push(_routes.SETTINGS);
    }
  };

  myVouchersClick = () => {
    if (window.innerWidth >= 1024) {
      this.menuOnClick('my-vouchers');
    } else {
      this.CloseNav();
    }
    const { history } = this.props;
    history.push(_routes.MY_VOUCHERS);
  };

  menuOnClick = key => {
    const { menuOnClick } = this.props;
    menuOnClick(key);
  };

  comingSoonClick = () => {
    alert('Hey! Feature coming soon. stay tuned.');
  };

  customerCareClick = () => {
    const { history } = this.props;
    history.push(_routes.FEEDBACK);
  };

  settingsClickHandler = () => {
    // alert();
    this.menuOnClick('settings');
    // const { history } = this.props;
    const { hideHamburgerMenu } = this.props;
    hideHamburgerMenu();
    // history.push(_routes.SETTINGS);
  };

  restaurantFinderClickHandler = () => {
    if (window.innerWidth < 1023) {
      const { hideHamburgerMenu } = this.props;
      hideHamburgerMenu();
    }
    const { history } = this.props;
    history.push(_routes.STORE_LOCATOR);
  };

  myCrownClick = () => {
    const { userAvailablePoints } = this.state;
    if (window.innerWidth >= 1024) {
      this.menuOnClick('crown-history');
    } else {
      this.CloseNav();
    }
    const { history } = this.props;
    history.push({
      pathname: _routes.CROWN_HISTORY,
      state: userAvailablePoints
    });
  };

  supportClick = () => {
    if (window.innerWidth >= 1024) {
      this.menuOnClick('support');
    } else {
      this.CloseNav();
    }
    const { history } = this.props;
    history.push(_routes.HELP_SUPPORT);
  };

  bkWallClick = () => {
    if (window.innerWidth >= 1024) {
      this.menuOnClick('bk-wall');
    } else {
      this.CloseNav();
    }
    const { history } = this.props;
    history.push(_routes.BK_WALL);
  };

  onMenuClickHandler = key => {
    if (window.innerWidth < 1023) {
      const { hideHamburgerMenu } = this.props;
      hideHamburgerMenu();
    }
    switch (key) {
      case 'recent-orders':
        this.recentOrderClick();
        break;
      case 'saved-addressess':
        this.savedAddressesClick();
        break;
      case 'bk-wall':
        this.bkWallClick();
        break;
      case 'notifications':
        this.notificationClick();
        break;
      case 'settings':
        this.settingsClick();
        break;
      case 'crown-history':
        this.myCrownClick();
        break;
      case 'my-vouchers':
        this.myVouchersClick();
        break;
      case 'support':
        this.supportClick();
        break;
      case 'customer-care':
        this.customerCareClick();
        break;
      default:
        this.comingSoonClick();
        break;
    }
    return null;
  };

  render() {
    const {
      menuListActivekey,
      userData,
      showSettingsList,
      activeSettingsList
    } = this.state;
    const { theme, profileOnClick, userAvailablePoints } = this.props;
    const { innerWidth } = window;
    const { onMenuClickHandler, restaurantFinderClickHandler } = this;

    return (
      <div className="act-sidemenu">
        {innerWidth < 1023 ? (
          <div className="act-sidemenu__options">
            <div className="act-sidemenu__close">
              <img
                src={getLeftArrowImage(theme)}
                alt="back"
                className="act-sidemenu__close-img"
                onClick={() => this.CloseNav()}
                role="presentation"
              />
            </div>
            <div className="act-sidemenu__close">
              <img
                src={getNotificationImage(theme)}
                alt="notification"
                className="act-sidemenu__close-img"
                role="presentation"
                onClick={() => this.onMenuClickHandler('notifications')}
              />
              <div className={`act-sidemenu__notify ${theme}`} />
            </div>
          </div>
        ) : (
          ''
        )}
        <div className="act-sidemenu__profile">
          <AccountSideMenuProfile
            onClick={profileOnClick}
            data={userData}
            theme={theme}
          />
        </div>
        <div className="act-sidemenu__list">
          <AccountSideMenuList
            activeSettingsList={activeSettingsList}
            showSettingsList={showSettingsList}
            activeKey={menuListActivekey}
            theme={theme}
            handleShowSettingsList={this.props.handleShowSettingsList}
            onMenuClick={onMenuClickHandler}
            userAvailablePoints={userAvailablePoints}
          />
        </div>
        <div
          className="act-sidemenu__restaurant-finder-card"
          onClick={() => restaurantFinderClickHandler()}
          role="presentation"
        >
          <div className="act-sidemenu__restaurant-finder">
            <div className="act-sidemenu__restaurant-finder-image">
              <img src={getRestaurantFinderImage(theme)} alt="restaurant" />
              <p className="act-sidemenu__restaurant-finder-title">
                Restaurant Finder
              </p>
            </div>
            <div>
              <img src={getRightArrowImage(theme)} alt="rightarrow" />
            </div>
          </div>
        </div>
        {/* <div className="act-sidemenu__reference">
              <ReferAndEarn
                title="REFER a friend"
                description="Get 100BK crowns after each friend’s first order"
                image={CrownImage}
              />
              <div className="act-sidemenu__reference-divider" />
              <ReferAndEarn
                title="Rate Us"
                description="How have you liked the Burger King app so far?"
                image={PlayStoreIcon}
              />
            </div> */}
        {/* </> */}
      </div>
    );
  }
}

export default withRouter(AccountSideMenu);
