import { put, call, fork, takeEvery } from 'redux-saga/effects';

import {
  ORDER_HISTORY,
  FEEDBACK_STATUS,
  TICKET_STATUS,
  IS_VIEWED_UPDATE_API,
  FEEDBACK_API_V2,
  UPDATE_FEEDBACK_API,
  ORDER_DETAILS
} from '../actionTypes';
import {
  ORDER_HISTORY_LIST_API,
  FEEDBACK_STATUS_RESPONSE_API,
  GET_TICKET_STATUS_API,
  IS_VIEWED_UPDATE,
  FEEDBACK_API_V2_LATEST,
  UPDATE_FEEDBACK,
  ORDER_DETAILS_API
} from '../../constants/api-constants';

import * as API from '../../utils/ajax';
import * as Actions from './actions';

function* getOrderHistoryListAsync(action) {
  // const { meta } = action;
  try {
    const response = yield call(
      API.get,
      `${ORDER_HISTORY_LIST_API}?offset=${action.payload.offset}&page_no=${action.payload.page}`,
      action.payload
    );
    yield put(Actions.getOrderHistoryListSuccess(response, action));
  } catch (error) {
    yield put(Actions.getOrderHistoryListError(error, action));
  }
}

function* getOrderDetailsAsync(action) {
  // const { meta } = action;
  try {
    const response = yield call(
      API.post,
      `${ORDER_DETAILS_API}`,
      action.payload
    );
    yield put(Actions.getOrderDetailsSuccess(response, action));
  } catch (error) {
    yield put(Actions.getOrderDetailsError(error, action));
  }
}

function* getFeedbackStatusListAsync(action) {
  const { meta } = action;
  try {
    const response = yield call(
      API.get,
      `${FEEDBACK_STATUS_RESPONSE_API}`,
      action.payload
    );
    yield put(Actions.getFeedbackStatusListSuccess(response, action, meta));
  } catch (error) {
    yield put(Actions.getFeedbackStatusListError(error, action));
  }
}

function* getTicketStatusListAsync(action) {
  const { meta } = action;
  try {
    const response = yield call(
      API.get,
      `${GET_TICKET_STATUS_API}`,
      action.payload
    );
    yield put(Actions.getTicketStatusListSuccess(response, action, meta));
  } catch (error) {
    yield put(Actions.getTicketStatusListError(error, action));
  }
}

function* getIsViewdUpdateAsync(action) {
  const { meta } = action;
  try {
    const response = yield call(
      API.post,
      `${IS_VIEWED_UPDATE}`,
      action.payload
    );
    yield put(Actions.isViewedUpdateApiSuccess(response, action, meta));
  } catch (error) {
    yield put(Actions.isViewedUpdateApiError(error, action));
  }
}

function* postFeedbackV2Async(action) {
  const { meta } = action;
  try {
    const response = yield call(
      API.post,
      `${FEEDBACK_API_V2_LATEST}`,
      action.payload
    );
    yield put(Actions.feedbackApiV2Success(response, action, meta));
  } catch (error) {
    yield put(Actions.feedbackApiV2Error(error, action));
  }
}

function* updateFeedbackApiAsync(action) {
  const { meta } = action;
  try {
    const response = yield call(API.post, `${UPDATE_FEEDBACK}`, action.payload);
    yield put(Actions.updateFeedbackApiSuccess(response, action, meta));
  } catch (error) {
    yield put(Actions.updateFeedbackApiError(error, action));
  }
}

export function* watchOrderHistorySaga() {
  yield takeEvery(ORDER_HISTORY, getOrderHistoryListAsync);
  yield takeEvery(ORDER_DETAILS, getOrderDetailsAsync);
  yield takeEvery(FEEDBACK_STATUS, getFeedbackStatusListAsync);
  yield takeEvery(TICKET_STATUS, getTicketStatusListAsync);
  yield takeEvery(IS_VIEWED_UPDATE_API, getIsViewdUpdateAsync);
  yield takeEvery(FEEDBACK_API_V2, postFeedbackV2Async);
  yield takeEvery(UPDATE_FEEDBACK_API, updateFeedbackApiAsync);
}

const orderHistorySaga = [fork(watchOrderHistorySaga)];
export default orderHistorySaga;
