import './index.scss';

import React from 'react';

import Button from '../button';

// import Modal from '../../containers/modal';

const LocationRestriction = props => {
  const {title,description,okayClick,theme} = props;
  return (
    // <Modal centerPopUp hideClose onCloseHandler={okayClick}>
    <div className="location-restrict_wrapper">
      <div className="location-restrict">
        <div className="location-restrict__content">
          <p className="location-restrict__title">
            {title}
          </p>
          <p className="location-restrict__description">
            {description}
          </p>
          <div className="location-restrict__button">
            <div className="location-restrict__okay-btn" onClick={okayClick} role="presentation">
              <Button content="okay" size="is-small" theme={theme}/>
            </div>
          </div>
        </div>
      </div>
    </div>
    // </Modal>
  );
};

export default LocationRestriction;
