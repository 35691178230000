/* eslint-disable no-undef */
import './index.scss';

import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';

import Modal from '../../containers/modal';
import OnBoardingLocation from '../../containers/onboarding-location';

import SetAddress from '../../components/set-address';
// import OnboardNoData from '../../components/onboard-ondata';

import {
  addAddress,
  getAddressList,
  getDeleteAddress,
  getOutlet
} from '../../burger-king-lib/redux/address/actions';
import { getHomeApiList } from '../../burger-king-lib/redux/home/actions';
import {
  clearRedisCart
  // getOutletChange1
} from '../../burger-king-lib/redux/redisCart/actions';

import { LocalSessionKey } from '../../burger-king-lib/constants/app-constants';
import { getOutletChange1 } from '../../burger-king-lib/redux/cart/actions';
import {
  LocalStorage,
  SessionStorage
} from '../../burger-king-lib/utils/storage.web';
import { THEME, ROUTES as _routes } from '../../constants/app-constants';
import { getCartItemPayload } from '../../payload-handling/cart';
import {
  deliveryAddressStorage,
  restaurantAddressStorage
} from '../../utils/services';

const { RESTAURANT } = THEME;

class OnboardPage extends Component {
  constructor() {
    super();
    this.state = {
      addressList: [],
      outletList: [],
      editAddressData: {},
      isLoggedIn: LocalStorage.get(LocalSessionKey.USER_DATA) || false,
      currentProgress: 'location',
      showLoader: true
    };
    this.latLng = {
      lat: '',
      lng: ''
    };
    this.isAlertShowedOnce = true;
  }

  componentDidMount() {
    const { getListBasedOnTheme } = this;
    getListBasedOnTheme();
  }

  componentDidUpdate(prevProps) {
    const { addressData, theme } = this.props;
    if (
      addressData &&
      addressData.getAddressList &&
      addressData.getAddressList !== prevProps.addressData.getAddressList
    ) {
      if (addressData.getAddressList.content) {
        this.setAddressList(addressData.getAddressList.content);
      }
      if (addressData.getAddressList.message) this.toggleLoader();
      if (
        addressData.getAddressList.error &&
        addressData.getAddressList.message === 'No Address'
      )
        this.setAddressList([]);
    }
    if (
      addressData &&
      addressData.addAddress !== prevProps.addressData.addAddress
    ) {
      if (addressData.addAddress.error === 0) {
        if (!this.isAlertShowedOnce) {
          this.isAlertShowedOnce = true;
          this.addAddressSuccessHandler();
          alert(addressData.addAddress.message);
        }
      } else if (addressData.addAddress.error) {
        alert(addressData.addAddress.message);
      }
    }

    if (
      addressData &&
      addressData.deleteAddress !== prevProps.addressData.deleteAddress
    ) {
      if (addressData.deleteAddress.error === 0) {
        if (!this.isAlertShowedOnce) {
          this.isAlertShowedOnce = true;
          this.getAddressListData();
          alert('Address deleted successfully');
        }
      } else if (
        addressData.deleteAddress.error &&
        addressData.deleteAddress.message
      ) {
        alert(addressData.deleteAddress.message);
      }
    }

    if (
      addressData &&
      addressData.outlets &&
      addressData.outlets !== prevProps.addressData.outlets
    ) {
      if (addressData.outlets.content) {
        this.setOutletList(addressData.outlets.content);
      }
      if (addressData.outlets.message) this.toggleLoader();
    }

    if (theme !== prevProps.theme) {
      const { getListBasedOnTheme } = this;
      getListBasedOnTheme();
    }
  }

  getAddressListData = () => {
    const userId = LocalStorage.get(LocalSessionKey.USER_ID);
    const userData = LocalStorage.get(LocalSessionKey.USER_DATA);
    if (userData && userId) {
      const { getAddressListData } = this.props;
      getAddressListData();
    }
  };

  getOutletListData = () => {
    const { getOutletListData } = this.props;
    const latLng = LocalStorage.get(LocalSessionKey.LAT_LNG);
    const payload = {
      lat: (latLng && latLng.lat) || 0,
      long: (latLng && latLng.lng) || 0
    };
    this.setState({ showLoader: true });
    getOutletListData(payload);
  };

  getListBasedOnTheme = () => {
    const { theme } = this.props;
    this.setState({ showLoader: true });

    if (theme === RESTAURANT) {
      const { getOutletListData } = this;
      getOutletListData();
    } else {
      const { getAddressListData } = this;
      getAddressListData();
    }
  };

  getHomeApiListData = outlet_id => {
    const payload = {
      outlet_id: outlet_id.toString()
    };
    const { getHomeApiListData } = this.props;
    getHomeApiListData(payload);
  };

  setAddressList = data => {
    this.setState({ addressList: data });
  };

  setOutletList = data => {
    this.setState({ outletList: data });
  };

  toggleLoader = () => {
    this.setState({ showLoader: false });
  };

  outletClickHandler = data => {
    // console.log(data, 'value');
    const {
      toggleDisplayOnBoardModal,
      history,
      theme,
      hideToggle,
      addressChangeHandler
    } = this.props;
    const { getHomeApiListData } = this;
    const { outlet_id } = data;
    const { location } = history;
    const { pathname } = location;

    data.location = `${data.outlet_name}, ${data.address}`;
    addressChangeHandler(data);
    restaurantAddressStorage.set(data);
    SessionStorage.set(LocalSessionKey.OUTLET_ID, outlet_id);
    SessionStorage.set(LocalSessionKey.RESTAURANT_DETAIL, data);

    if (!hideToggle) {
      const { getCartItemData } = this.props;
      const payload = getCartItemPayload(theme);
      getCartItemData(payload);

      if (pathname !== _routes.HOME) {
        history.push(_routes.HOME);
      } else {
        getHomeApiListData(outlet_id);
      }
    }

    toggleDisplayOnBoardModal(data);
  };

  savedAddressClickHndler = data => {
    const { cart, getOutletChangeData } = this.props;
    const script = document.createElement('script');
    script.src = '//cdnt.netcoresmartech.com/smartechclient.js';
    script.onload = () => {
      smartech('dispatch', 'af_setdeliverylocation', {
        location: `${data.address},${data.location}`,
        lat: data.latitude,
        long: data.longitude
      });
    };
    document.head.appendChild(script);
    if (cart !== 'Cart') deliveryAddressStorage.set(data);
    this.onCloseModalHandler(data);
    getOutletChangeData(data);
  };

  goBackSetAddressClickHandler = () => {
    this.setState({ currentProgress: 'location', editAddressData: {} });
  };

  onSaveAddressClickHandler = payload => {
    this.isAlertShowedOnce = false;
    const { addAddressData } = this.props;
    addAddressData(payload);
  };

  addAddressSuccessHandler = () => {
    this.getAddressListData();
    this.setState({ currentProgress: 'location', editAddressData: {} });
  };

  addAddressClickHandler = () => {
    this.setState({ currentProgress: 'setAddress' });
    // this.getAddressListData();
  };

  addressChangeHandler = value => {
    this.setState({ inputValue: value });
  };

  onCloseModalHandler = inputAddressValue => {
    const extra = inputAddressValue;
    const {
      toggleDisplayOnBoardModal,
      addressChangeHandler
      // theme
    } = this.props;
    const { inputValue } = this.state;
    if (inputValue) inputAddressValue = inputValue;
    addressChangeHandler(inputAddressValue, extra);
    toggleDisplayOnBoardModal();
    // if (theme !== RESTAURANT || (restaurantAddressStorage.get() && restaurantAddressStorage.get().outlet_id)) {
    //   toggleDisplayOnBoardModal();
    // } else {
    //   alert('Please select restaurant to proceed');
    // }
  };

  deleteAddressClickHandler = data => {
    const { getDeleteAddressData } = this.props;
    const { id } = data;
    const payload = { id };
    getDeleteAddressData(payload);
    this.setState({ showLoader: true });
    this.isAlertShowedOnce = false;
  };

  editAddressClickHanlder = data => {
    this.setState({ currentProgress: 'setAddress', editAddressData: data });
  };

  renderContent = () => {
    const {
      addressList,
      outletList,
      isLoggedIn,
      currentProgress,
      showLoader,
      editAddressData
    } = this.state;
    const {
      savedAddressClickHndler,
      deleteAddressClickHandler,
      editAddressClickHanlder,
      outletClickHandler,
      getOutletListData
    } = this;
    const {
      hideSearchBar,
      theme,
      themeHandler,
      hideToggle,
      cartData,
      getClearCartData,
      renderRestaurantList,
      cart
    } = this.props;
    let dom;
    switch (currentProgress) {
      case 'setAddress':
        dom = (
          <SetAddress
            editData={editAddressData}
            goBackSetAddressClick={this.goBackSetAddressClickHandler}
            onSaveAddressClick={this.onSaveAddressClickHandler}
            theme={theme}
          />
        );
        break;
      case 'location':
        dom = (
          <OnBoardingLocation
            getClearCartData={getClearCartData}
            cartData={cartData}
            hideToggle={hideToggle}
            themeHandler={themeHandler}
            theme={theme}
            onCloseModalHandler={this.onCloseModalHandler}
            addressChangeHandler={this.addressChangeHandler}
            addressList={addressList}
            showLoader={showLoader}
            isLoggedIn={isLoggedIn}
            savedAddressClickHndler={savedAddressClickHndler}
            addAddressClickHandler={this.addAddressClickHandler}
            hideSearchBar={hideSearchBar}
            deleteAddressClick={deleteAddressClickHandler}
            editAddressClick={editAddressClickHanlder}
            outletList={outletList}
            outletClickHandler={outletClickHandler}
            getOutletListData={getOutletListData}
            renderRestaurantList={renderRestaurantList}
            cart={cart}
          />
        );
        break;
      default:
        dom = '';
    }
    return dom;
  };

  render() {
    const { theme, cart } = this.props;
    const { currentProgress, isLoggedIn } = this.state;
    return (
      <Modal
        isMobileFullLayout
        onCloseHandler={this.onCloseModalHandler}
        modalCustomClass={
          currentProgress === 'location' && cart !== 'Cart'
            ? 'onboarding_modal'
            : ''
        }
        contentCustomClass={
          currentProgress === 'location' && cart !== 'Cart'
            ? 'onboarding_modal-content'
            : ''
        }
      >
        <div className={`onboard ${theme}`}>{this.renderContent()}</div>
      </Modal>
    );
  }
}

OnboardPage.propTypes = {
  theme: PropTypes.string
};

OnboardPage.defaultProps = {
  theme: ''
};

const mapStateToProps = state => {
  return {
    addressData: state.address,
    cartData: state.cart
  };
};

const mapDispatchToProps = dispatch => {
  return {
    getAddressListData: payloadData => dispatch(getAddressList(payloadData)),
    addAddressData: payloadData => dispatch(addAddress(payloadData)),
    getDeleteAddressData: payloadData =>
      dispatch(getDeleteAddress(payloadData)),
    getOutletListData: payloadData => dispatch(getOutlet(payloadData)),
    getHomeApiListData: payloadData => dispatch(getHomeApiList(payloadData)),
    getCartItemData: payloadData => dispatch(getCartItem(payloadData)),
    getClearCartData: payloadData => dispatch(clearRedisCart(payloadData)),
    getOutletChangeData: payloadData => dispatch(getOutletChange1(payloadData))
  };
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(OnboardPage)
);
