/* eslint-disable no-undef */
/* eslint-disable no-nested-ternary */
import './index.scss';

import React, { Component } from 'react';
import { withRouter } from 'react-router';

import RestaurantCard from '../../components/restaurant-card';
import ToggleBetweenValues from '../../components/toggle-between-values';
import AddressCard from '../../components/address-card';
import Loader from '../../components/loader-spin';
import Button from '../../components/button';
import PlusSignIcon from '../../../assets/images/plus-sign.svg';

import {
  getDetectLocationImage,
  getLeftArrowImage,
  getPlusImage
} from '../../utils/imageChanges';
import { ROUTES as _routes, THEME } from '../../constants/app-constants';
import {
  LocalStorage,
  SessionStorage
} from '../../burger-king-lib/utils/storage.web';
import { LocalSessionKey } from '../../burger-king-lib/constants/app-constants';

import OnboardNoData from '../../components/onboard-ondata';
import ToggleCartEmpty from '../../components/toggle-theme-cart-empty';
import GoogleMapSearch from '../../components/map-search';
import { Analytics } from '../../helpers/analytics';

const { RESTAURANT, DELIVERY } = THEME;

class OnBoardingLocation extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isMobileView: this.isMobileView(),
      inputValue: { location: '', pincode: '' },
      showToggleEmptyCartModal: false,
      showNoLocationFound: false,
      latLong: {
        lat: '',
        lng: ''
      },
      city_name: ''
    };
    this.latLng = {
      lat: '',
      lng: ''
    };
    // this.abortController = new AbortController();
  }

  componentDidUpdate(prevProps) {
    const { cartData } = this.props;
    if (
      cartData &&
      cartData !== prevProps.cartData &&
      cartData.clearCart !== prevProps.cartData.clearCart
    ) {
      if (cartData.clearCart.error === 0) {
        this.cancelToggleChangeHandler();
      }
    }
  }

  isMobileView = () => {
    if (window.innerWidth > 1023) {
      return false;
    }
    return true;
  };

  onToggleChange = e => {
    const { cartData } = this.props;
    if (
      cartData &&
      cartData.getCartItem &&
      cartData.getCartItem.content &&
      cartData.getCartItem.content[0] &&
      cartData.getCartItem.content[0].cart_id
    ) {
      this.setState({ showToggleEmptyCartModal: true });
    } else {
      const { themeHandler } = this.props;
      if (e.target.checked) {
        // SessionStorage.set(LocalSessionKey.RESTAURANT_DETAIL, {});
        themeHandler('delivery');
      } else {
        themeHandler('takeaway');
      }
      const { history } = this.props;
      const { location } = history;
      const { pathname } = location;
      if (pathname !== _routes.HOME) history.push(_routes.HOME);
    }
  };

  searchOnChangeHandler = e => {
    const { inputValue } = this.state;
    inputValue.location = e;
    this.setState({ inputValue });
  };

  clearSearchInput = () => {
    this.searchOnChangeHandler('');
    this.setLatLng({ lat: '', lng: '' });
    // this.setState({ showNoLocationFound: false });
  };

  setLatLng = json => {
    this.latLng = json;
    LocalStorage.set(LocalSessionKey.LAT_LNG, json);
  };

  getCurrentLocation = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        position => {
          if (
            position &&
            position.coords &&
            position.coords.latitude &&
            position.coords.longitude
          ) {
            const json = {
              lat: position.coords.latitude,
              lng: position.coords.longitude
            };
            const { google } = window;
            const geocoder = new google.maps.Geocoder();
            geocoder.geocode(
              { latLng: json },
              (results, status) => {
                if (status === google.maps.GeocoderStatus.OK) {
                  if (
                    results &&
                    results.length &&
                    results[1] &&
                    results[1].formatted_address
                  ) {
                    let pincode = '';
                    let city = '';
                    results[1].address_components.forEach(data => {
                      if (data && data.types) {
                        const filter = data.types.filter(
                          item => item === 'postal_code'
                        );
                        const filters = data.types.filter(
                          item => item === 'locality'
                        );
                        if (filters && filters.length) city = data.long_name;
                        if (filter && filter.length) pincode = data.long_name;
                      }
                      return data;
                    });
                    this.setInputValue({
                      location: results[1].formatted_address,
                      pincode,
                      latitude: json.lat,
                      longitude: json.lng,
                      city
                    });
                    const fbscript = document.createElement('script');
                    fbscript.src = '//connect.facebook.net/en_US/fbevents.js';
                    fbscript.onload = () => {
                      fbq('track', 'FindLocation', {
                        af_location: results[1].formatted_address,
                        af_lat: json.lat,
                        af_long: json.lng,
                        af_city: city
                      });
                    };
                    document.head.appendChild(fbscript);
                    const script = document.createElement('script');
                    script.src = '//cdnt.netcoresmartech.com/smartechclient.js';
                    script.onload = () => {
                      smartech('dispatch', 'af_detectlocation', {
                        location: results[1].formatted_address,
                        lat: json.lat,
                        long: json.lng
                      });
                    };
                    document.head.appendChild(script);
                    Analytics.logEventWithVariable(
                      Analytics.Order.location,
                      JSON.stringify({
                        location: results[1].formatted_address,
                        lat: json.lat,
                        long: json.lng
                      })
                    );
                  }
                }
              },
              this
            );
            this.setLatLng(json);
            this.setState({ latLong: json });
            // onloadCurrentmap(true,json);
          }
          // eslint-disable-next-line no-unused-vars
        },
        error => {
          alert('Unable to fetch the location. Please enter manually');
        }
      );
    } else {
      alert('Unable to fetch the location. Please enter manually');
    }
  };

  getPincode = latLng => {
    const { google } = window;
    const geocoder = new google.maps.Geocoder();
    geocoder.geocode(
      { latLng },
      (results, status) => {
        if (status === google.maps.GeocoderStatus.OK) {
          if (
            results &&
            results.length &&
            results[1] &&
            results[1].formatted_address
          ) {
            let pincode = '';
            let city = '';
            results[1].address_components.forEach(data => {
              if (data && data.types) {
                const filter = data.types.filter(
                  item => item === 'postal_code'
                );
                const filters = data.types.filter(item => item === 'locality');
                if (filters && filters.length) city = data.long_name;
                if (filter && filter.length) pincode = data.long_name;
              }
              return data;
            });
            this.setInputValue({
              location: results[1].formatted_address,
              pincode,
              latLng,
              city
            });
          }
        }
      },
      this
    );
  };

  confirmToggleChangeHandler = () => {
    const { getClearCartData, theme, themeHandler } = this.props;
    if (theme === 'delivery') {
      themeHandler('takeaway');
    } else {
      // SessionStorage.set(LocalSessionKey.RESTAURANT_DETAIL, {});
      themeHandler('delivery');
    }
    const { history } = this.props;
    const { location } = history;
    const { pathname } = location;
    if (pathname !== _routes.HOME) history.push(_routes.HOME);
    getClearCartData();
    this.setState({ showToggleEmptyCartModal: false });
  };

  cancelToggleChangeHandler = () => {
    this.setState({ showToggleEmptyCartModal: false });
  };

  setInputValue = (value, isCloseModal) => {
    const { addressChangeHandler, onCloseModalHandler, theme } = this.props;
    this.setState({ inputValue: value });
    addressChangeHandler(value);
    if (isCloseModal) {
      if (theme === RESTAURANT) {
        LocalStorage.set(LocalSessionKey.LAT_LNG, value.latLng);
        onCloseModalHandler(value);
        // const { getOutletListData } = this.props;
        // getOutletListData();
      } else onCloseModalHandler(value);
    }
  };

  onSetLocation = () => {
    const { inputValue } = this.state;
    const latlong = inputValue.latLng || this.latLng;
    const script = document.createElement('script');
    script.src = '//cdnt.netcoresmartech.com/smartechclient.js';
    script.onload = () => {
      smartech('dispatch', 'af_setdeliverylocation', {
        af_location: inputValue.location,
        af_lat: latlong.lat,
        af_long: latlong.lng,
        af_city: inputValue.city
      });
    };
    document.head.appendChild(script);
    Analytics.logEventWithVariable(
      Analytics.Order.location,
      JSON.stringify({
        af_location: inputValue.location,
        af_lat: latlong.lat,
        af_long: latlong.lng,
        af_city: inputValue.city
      })
    );
    const { onCloseModalHandler } = this.props;
    onCloseModalHandler();
  };

  onCenter = value => {
    if (value === false) {
      this.setState({ latLong: { lat: '', lng: '' } });
    }
  };

  onMarkerChange = json => {
    this.getPincode(json);
    this.setLatLng(json);
    this.setState({ isLoad: false });
  };

  renderAddressList = () => {
    const {
      addressList,
      savedAddressClickHndler,
      deleteAddressClick,
      editAddressClick,
      theme
    } = this.props;
    if (addressList && addressList.length)
      return (
        <AddressCard
          addressList={addressList}
          onClick={savedAddressClickHndler}
          deleteAddressHandler={deleteAddressClick}
          editAddressHanlder={editAddressClick}
          theme={theme}
        />
      );
    return (
      <div className="onboarding__location__no-item">No address found!!!</div>
    );
  };

  renderOutletList = () => {
    const { outletList, outletClickHandler } = this.props;
    if (outletList && outletList.length) {
      return (
        <div className="onboarding__restaurant">
          <p className="onboarding__restaurant-title">
            {' '}
            select a restaurant to order from{' '}
          </p>
          {outletList.map(data => {
            return <RestaurantCard data={data} onClick={outletClickHandler} />;
          })}
        </div>
      );
    }
    return <OnboardNoData />;
  };

  renderRestaurantList = () => {
    const { showLoader, theme, renderRestaurantList } = this.props;
    if (renderRestaurantList)
      return showLoader ? <Loader theme={theme} /> : this.renderOutletList();
    return '';
  };

  renderContent = () => {
    const { isLoggedIn, showLoader, theme, addressList } = this.props;
    let dom;

    switch (theme) {
      case RESTAURANT:
        dom = this.renderRestaurantList();
        break;
      default:
        dom = (
          <div className="onboarding__address">
            {isLoggedIn ? (
              <>
                <p className="onboarding__address-title">
                  {addressList && addressList.length && addressList.length > 1
                    ? 'Saved Addresses'
                    : 'Saved Address'}
                </p>
                {!showLoader ? (
                  this.renderAddressList()
                ) : (
                  <Loader theme={theme} />
                )}
              </>
            ) : (
              ''
            )}
          </div>
        );
    }
    return dom;
  };

  render() {
    const {
      theme,
      onCloseModalHandler,
      hideSearchBar,
      hideToggle,
      isLoggedIn,
      addAddressClickHandler,
      getCurrentLocation,
      cart
    } = this.props;
    const {
      isMobileView,
      inputValue,
      showToggleEmptyCartModal,
      showNoLocationFound,
      latLong
    } = this.state;
    return (
      // <div style={{overflowY: 'auto'}}>
      <div>
        <div
          className={
            cart === 'Cart'
              ? 'onboarding__header-delivery'
              : 'onboarding__header'
          }
          // ${
          //   hideToggle ? 'onboarding__header_notoggle' : ''
          // }`
        >
          {isMobileView ? (
            <div className="onboarding__arrow-image">
              <img
                src={getLeftArrowImage(theme)}
                alt="arrow"
                className="onboarding__arrow-img"
                role="presentation"
                onClick={() => onCloseModalHandler()}
              />
            </div>
          ) : (
            ''
          )}
          <div>
            {/* <ToggleBetweenValues
                theme={theme}
                leftContent="delivery"
                rightContent="restaurant"
                onChange={this.onToggleChange}
                checked={theme === DELIVERY || false}
              /> */}
            <p className="onboarding__title">CHANGE LOCATION</p>
          </div>
        </div>
        <div className="onboarding__bar">
          {!hideSearchBar ? (
            <GoogleMapSearch
              isMobileView={isMobileView}
              inputValue={inputValue}
              onSearchChange={this.searchOnChangeHandler}
              setInputValue={this.setInputValue}
              setLatLng={this.setLatLng}
              onClearClick={this.clearSearchInput}
              theme={theme}
              noLocationFound={this.noLocationFound}
              center={latLong}
              onMarkerPosition={this.onMarkerChange}
              onCenterChange={this.onCenter}
              // getCurrentLocation={getCurrentLocation}
            />
          ) : (
            ''
          )}

          {/* {theme === RESTAURANT ? (
            <div className="onboard__filter">
              <Button
                content="Filters"
                theme={theme}
                image={FilterIconImage}
                type="block"
                size="is-large"
              />
            </div>
          ) : (
              ''
            )} */}
        </div>
        {isMobileView && !hideSearchBar ? (
          <>
            <div
              className="onboarding__location-mobile"
              role="presentation"
              onClick={() => this.getCurrentLocation()}
            >
              <div>
                <img
                  src={getDetectLocationImage(theme)}
                  alt="detect location"
                  className="onboarding__location-img"
                />
              </div>
              <div className="onboarding__detect-mobile">
                <p className="onboarding__detect-name">
                  Detect current location
                </p>
                <p className="onboarding__detect-gps">Using GPS</p>
              </div>
            </div>
            <div className="onboarding__location-mobile-button">
              <Button
                content="Confirm Location"
                className="onboarding__location-mobile_button"
                onClick={this.onSetLocation}
                theme={theme}
              />
            </div>
          </>
        ) : isLoggedIn && theme === DELIVERY && cart === 'Cart' ? (
          ''
        ) : (
          <div className="onboarding__location-button">
            <Button
              content="Confirm Location"
              className="onboarding__location_button"
              onClick={this.onSetLocation}
              theme={theme}
            />
          </div>
        )}
        {isLoggedIn && theme === DELIVERY && cart === 'Cart' ? (
          <div
            className={`onboarding__add  u_cursor_pointer ${
              hideSearchBar ? 'onboarding__add-space' : ''
            }`}
            role="presentation"
            onClick={() => addAddressClickHandler()}
          >
            <div className="onboarding__image">
              <img src={getPlusImage(theme)} alt="plus sign" />
              <span className="onboarding__add-title">Add Address</span>
            </div>
          </div>
        ) : (
          ''
        )}
        {cart === 'Cart' ? this.renderContent() : ''}
        {/* {showNoLocationFound ? <OnboardNoData isEnterLocationWrong /> : ''} */}
        {showToggleEmptyCartModal ? (
          <ToggleCartEmpty
            onConfirmClick={this.confirmToggleChangeHandler}
            onCancelClick={this.cancelToggleChangeHandler}
            theme={theme}
          />
        ) : (
          ''
        )}
      </div>
    );
  }
}

export default withRouter(OnBoardingLocation);
