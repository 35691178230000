import './index.scss';

import React from 'react';

const Charity = props => {
  const { disabled, checked, onChange, theme, data } = props;
  return (
    <div className="charity_wrapper">
      <div className="charity">
        <div className="charity__content">
          <label className="charity__label">
            <input
              type="checkbox"
              className="charity__checkbox"
              disabled={disabled}
              name="charity"
              id="charity"
              checked={checked}
              onChange={e => onChange(e)}
            />
            <span
              className={`charity__custom ${theme} ${
                checked ? '' : 'charity__custom_uncheck'
              } ${disabled ? 'charity__custom_disabled' : ''} `}
            />
          </label>
          <div className="charity__text">
            {data && data.product && data.product.name}
          </div>
        </div>
        <div className="charity__amount">
          ₹
          <span className="charity__currency">
            {data && data.product && data.product.price
              ? data.product.price
              : 0}
          </span>
        </div>
      </div>
    </div>
  );
};

export default Charity;
