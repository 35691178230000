import { put, call, fork, takeLatest, takeEvery } from 'redux-saga/effects';
import {
  // ADD_TO_CART,
  // GET_CART_ITEM,
  // ADD_REMOVE_CART_ITEM,
  GET_CLEAR_CART,
  CHECKOUT_CART,
  // CHECKOUT_CART_APP,
  GET_OUTLET_CHANGE,
  GET_ADDONS_LIST,
  GET_PAYMENT_STATUS,
  // GET_APP_PAYMENT_STATUS,
  GET_APPLY_COUPON,
  GET_REMOVE_COUPON,
  // OFFER_ADD_TO_CART,
  // SKD_OFFER_ADD_TO_CART,
  GET_CHARITY_PRODUCT,
  // CART_ADD_TO_CART,
  // PDP_ADD_TO_CART,
  // PLP_ADD_TO_CART,
  // CART_GET_CART_ITEM,
  GET_FEEDBACK,
  GET_LEGAL,
  RESEND_OFFER_OTP,
  VERIFY_OFFER_OTP,
  CANCEL_ORDER,
  GET_CONTROLLERS,
  JUSPAY_PAYMENT_METHOD_STATUS
  // GET_REDIS_CART_ITEM
} from '../actionTypes';
import {
  // ADD_TO_CART_API,
  GET_CART_ITEM_API,
  // ADD_REMOVE_CART_ITEM_API,
  // CLEAR_CART_API,
  // CHECKOUT_CART_API,
  // GET_OUTLETS_CHANGE_API,
  GET_ADDONS_API,
  // GET_PAYMENT_STATUS_API,
  CHECKOUT_CART_API_WEB,
  GET_PAYMENT_STATUS_WEB_API,
  // GET_APPLY_COUPON_API,
  // GET_REMOVE_COUPON_API,
  // ADD_TO_CART_UPDATED_API,
  CHARITY_PRODUCT_API,
  FEEDBACK_API,
  RESEND_OFFER_OTP_API,
  // VERIFY_OFFER_OTP_API,
  CANCEL_ORDER_API,
  GET_CONTROLLERS_API,
  GET_LEGAL_API,
  JUSPAY_PAYMENT_METHOD_API
  // GET_REDIS_CART_ITEM_API
} from '../../constants/api-constants';
import * as API from '../../utils/ajax';
import * as Actions from './actions';

// function* addToCartAsync(action) {
//   if (!action.payload.data[0].coupon_code) {
//     delete action.payload.data[0].coupon_code;
//   }
//   try {
//     const response = yield call(
//       API.post,
//       ADD_TO_CART_UPDATED_API,
//       action.payload
//     );
//     yield put(Actions.addToCartSuccess(response, action));
//   } catch (error) {
//     yield put(Actions.addToCartError(error, action));
//   }
// }

// function* cartAddToCartAsync(action) {
//   try {
//     const response = yield call(
//       API.post,
//       ADD_TO_CART_UPDATED_API,
//       action.payload
//     );
//     yield put(Actions.cartAddToCartSuccess(response, action));
//   } catch (error) {
//     yield put(Actions.cartAddToCartError(error, action));
//   }
// }

// function* pdpAddToCartAsync(action) {
//   try {
//     const response = yield call(
//       API.post,
//       ADD_TO_CART_UPDATED_API,
//       action.payload
//     );
//     yield put(Actions.pdpAddToCartSuccess(response, action));
//   } catch (error) {
//     yield put(Actions.pdpAddToCartError(error, action));
//   }
// }

// function* plpAddToCartAsync(action) {
//   try {
//     const response = yield call(
//       API.post,
//       ADD_TO_CART_UPDATED_API,
//       action.payload
//     );
//     yield put(Actions.plpAddToCartSuccess(response, action));
//   } catch (error) {
//     yield put(Actions.plpAddToCartError(error, action));
//   }
// }

// function* offerAddToCartAsync(action) {
//   try {
//     const response = yield call(
//       API.post,
//       ADD_TO_CART_UPDATED_API,
//       action.payload
//     );
//     yield put(Actions.offerAddToCartSuccess(response, action));
//   } catch (error) {
//     yield put(Actions.offerAddToCartError(error, action));
//   }
// }

// function* skdOfferAddToCartAsync(action) {
//   try {
//     const response = yield call(
//       API.post,
//       ADD_TO_CART_UPDATED_API,
//       action.payload
//     );
//     yield put(Actions.skdOfferAddToCartSuccess(response, action));
//   } catch (error) {
//     yield put(Actions.skdOfferAddToCartError(error, action));
//   }
// }

// function* getCartItemAsync(action) {
//   // const { meta } = action;
//   try {
//     const response = yield call(API.post, GET_CART_ITEM_API, action.payload);
//     yield put(Actions.getCartItemSuccess(response, action));
//   } catch (error) {
//     yield put(Actions.getCartItemError(error, action));
//   }
// }

// function* getRedisCartItemAsync(action) {
//   // const { meta } = action;
//   try {
//     const response = yield call(
//       API.get,
//       GET_REDIS_CART_ITEM_API,
//       action.payload
//     );
//     yield put(Actions.getRedisCartItemSuccess(response, action));
//   } catch (error) {
//     yield put(Actions.getRedisCartItemError(error, action));
//   }
// }

// function* cartGetCartItemAsync(action) {
//   const { meta } = action;
//   try {
//     const response = yield call(API.post, GET_CART_ITEM_API, action.payload);
//     yield put(Actions.cartGetCartItemSuccess(response, action));
//   } catch (error) {
//     yield put(Actions.cartGetCartItemError(error, action));
//   }
// }

// function* addRemoveCartItemAsync(action) {
//   try {
//     const response = yield call(
//       API.post,
//       ADD_REMOVE_CART_ITEM_API,
//       action.payload
//     );
//     yield put(Actions.addRemoveCartItemSuccess(response, action));
//   } catch (error) {
//     yield put(Actions.addRemoveCartItemError(error, action));
//   }
// }

// function* getClearCartAsync(action) {
//   // const { meta } = action;
//   try {
//     const response = yield call(API.get, CLEAR_CART_API, action.payload);
//     yield put(Actions.getClearCartSuccess(response, action));
//   } catch (error) {
//     yield put(Actions.getClearCartError(error, action));
//   }
// }

// function* getCheckoutCartAsync(action) {
//   try {
//     const response = yield call(
//       API.post,
//       CHECKOUT_CART_API_WEB,
//       action.payload
//     );
//     // console.log(`CHECKOUT_CART_API ---------- ${CHECKOUT_CART_API}`);
//     yield put(Actions.getCheckoutCartAppSuccess(response, action));
//   } catch (error) {
//     yield put(Actions.getCheckoutCartAppError(error, action));
//   }
// }

function* getCheckoutCartWebAsync(action) {
  // console.log('SAGA', action);
  try {
    const response = yield call(
      API.post,
      CHECKOUT_CART_API_WEB,
      action.payload
    );
    // console.log(`CHECKOUT_CART_API_WEB ---------- ${CHECKOUT_CART_API}`);
    yield put(Actions.getCheckoutWebCartSuccess(response, action));
  } catch (error) {
    yield put(Actions.getCheckoutWebCartError(error, action));
  }
}

// function* getOutletChangeAsync(action) {
//   if (Object.keys(action.payload).length != 4) {
//     return;
//   }
//   try {
//     const response = yield call(
//       API.post,
//       GET_OUTLETS_CHANGE_API,
//       action.payload
//     );
//     yield put(Actions.getOutletChangeSuccess(response, action));
//   } catch (error) {
//     yield put(Actions.getOutletChangeError(error, action));
//   }
// }

function* getAddonsListAsync(action) {
  try {
    const response = yield call(API.post, GET_ADDONS_API, action.payload);
    yield put(Actions.getAddonsListSuccess(response, action));
  } catch (error) {
    yield put(Actions.getAddonsListError(error, action));
  }
}

function* getPaymentWebStatusAsync(action) {
  // console.log(JSON.stringify(action, "ckkkkkk"));
  try {
    const response = yield call(
      API.post,
      `${GET_PAYMENT_STATUS_WEB_API}`,
      action.payload
    );
    yield put(Actions.getPaymentWebStatusSuccess(response, action));
  } catch (error) {
    yield put(Actions.getPaymentWebStatusError(error, action));
  }
}

function* getJusPayPaymentMethodStatusAsync(action) {
  try {
    const response = yield call(
      API.post,
      `${JUSPAY_PAYMENT_METHOD_API}`,
      action.payload
    );
    yield put(
      Actions.juspayPaymentMethodSuccess(response, action, action?.meta)
    );
  } catch (error) {
    yield put(Actions.juspayPaymentMethodError(error, action));
  }
}

// function* getAppPaymentStatusAsync(action) {
//   try {
//     const response = yield call(
//       API.post,
//       GET_PAYMENT_STATUS_API,
//       action.payload
//     );
//     yield put(Actions.getAppPaymentStatusSuccess(response, action));
//   } catch (error) {
//     yield put(Actions.getAppPaymentStatusError(error, action));
//   }
// }

// function* getApplyCouponAsync(action) {
//   try {
//     const response = yield call(API.post, GET_APPLY_COUPON_API, action.payload);
//     yield put(Actions.getApplyCouponSuccess(response, action));
//   } catch (error) {
//     yield put(Actions.getApplyCouponError(error, action));
//   }
// }

function* getLegalAsync(action) {
  try {
    const response = yield call(API.get, GET_LEGAL_API, action.payload);
    yield put(Actions.getLegalSuccess(response, action));
  } catch (error) {
    yield put(Actions.getLegalError(error, action));
  }
}

function* getCharityProductAsync(action) {
  try {
    const response = yield call(API.post, CHARITY_PRODUCT_API, action.payload);
    yield put(Actions.getCharityProductSuccess(response, action));
  } catch (error) {
    yield put(Actions.getCharityProductError(error, action));
  }
}

function* getFeedbackAsync(action) {
  try {
    const response = yield call(API.post, FEEDBACK_API, action.payload);
    yield put(Actions.getFeedbackSuccess(response, action));
  } catch (error) {
    yield put(Actions.getFeedbackError(error, action));
  }
}

// function* resendOfferOtpAsync(action) {
//   try {
//     const response = yield call(API.post, RESEND_OFFER_OTP_API, action.payload);
//     yield put(Actions.resendOfferOtpSuccess(response, action));
//   } catch (error) {
//     yield put(Actions.resendOfferOtpError(error, action));
//   }
// }

function* getControllersAsync(action) {
  try {
    const response = yield call(API.get, GET_CONTROLLERS_API, action.payload);
    yield put(Actions.getControllersSuccess(response, action));
  } catch (error) {
    yield put(Actions.getControllersError(error, action));
  }
}

// function* verifyOfferOtpAsync(action) {
//   try {
//     const response = yield call(API.post, VERIFY_OFFER_OTP_API, action.payload);
//     yield put(Actions.verifyOfferOtpSuccess(response, action));
//   } catch (error) {
//     yield put(Actions.verifyOfferOtpError(error, action));
//   }
// }

function* cancelOrderAsync(action) {
  // console.log(`CHECKOUT_CART_API ---------- ${CANCEL_ORDER_API}`);
  try {
    const response = yield call(API.post, CANCEL_ORDER_API, action.payload);
    yield put(Actions.cancelOrderSuccess(response, action));
  } catch (error) {
    yield put(Actions.cancelOrderError(error, action));
  }
}

export function* watchCartSaga() {
  // yield takeEvery(ADD_TO_CART, addToCartAsync);
  // yield takeEvery(CART_ADD_TO_CART, cartAddToCartAsync);
  // yield takeEvery(PDP_ADD_TO_CART, pdpAddToCartAsync);
  // yield takeEvery(PLP_ADD_TO_CART, plpAddToCartAsync);
  // yield takeEvery(OFFER_ADD_TO_CART, offerAddToCartAsync);
  // yield takeEvery(SKD_OFFER_ADD_TO_CART, skdOfferAddToCartAsync);
  // yield takeEvery(GET_CART_ITEM, getCartItemAsync);
  // yield takeEvery(GET_REDIS_CART_ITEM, getRedisCartItemAsync);
  // yield takeEvery(CART_GET_CART_ITEM, cartGetCartItemAsync);
  // yield takeLatest(ADD_REMOVE_CART_ITEM, addRemoveCartItemAsync);
  // yield takeLatest(GET_CLEAR_CART, getClearCartAsync);
  // yield takeLatest(CHECKOUT_CART_APP, getCheckoutCartAsync);
  yield takeLatest(CHECKOUT_CART, getCheckoutCartWebAsync);
  // yield takeLatest(GET_OUTLET_CHANGE, getOutletChangeAsync);
  yield takeLatest(GET_ADDONS_LIST, getAddonsListAsync);
  // yield takeLatest(GET_APP_PAYMENT_STATUS, getAppPaymentStatusAsync);
  yield takeLatest(GET_PAYMENT_STATUS, getPaymentWebStatusAsync);
  // yield takeLatest(GET_APPLY_COUPON, getApplyCouponAsync);
  // yield takeLatest(GET_REMOVE_COUPON, getRemoveCouponAsync);
  yield takeLatest(GET_CHARITY_PRODUCT, getCharityProductAsync);
  yield takeLatest(GET_LEGAL, getLegalAsync);
  yield takeLatest(GET_FEEDBACK, getFeedbackAsync);
  // yield takeLatest(RESEND_OFFER_OTP, resendOfferOtpAsync);
  // yield takeLatest(VERIFY_OFFER_OTP, verifyOfferOtpAsync);
  yield takeLatest(CANCEL_ORDER, cancelOrderAsync);
  yield takeLatest(GET_CONTROLLERS, getControllersAsync);
  yield takeLatest(
    JUSPAY_PAYMENT_METHOD_STATUS,
    getJusPayPaymentMethodStatusAsync
  );
}
const cartSaga = [fork(watchCartSaga)];
export default cartSaga;
