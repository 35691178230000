import { put, call, fork, takeLatest } from 'redux-saga/effects';

import {
  GET_SIGNUP,
  GET_VERIFY_OTP,
  GET_UPDATE_USER,
  GET_EDIT_USER,
  GET_USER_DETAIL,
  UPLOAD_PROFILE_PIC,
  SIGNUP_WITH_EMAIL,
  GENERATE_PHONE_OTP,
  REQUESTING_ACCOUNT_DELETE
} from '../actionTypes';
import {
  SIGNUP,
  VERIFY_OTP,
  UPDATE_USER_API,
  GET_EDIT_USER_API,
  GET_USER_DETAIL_API,
  SIGNUP_WITH_EMAIL_API,
  GENERATE_PHONE_OTP_API,
  REQUESTING_ACCOUNT_DELETE_API
} from '../../constants/api-constants';

import * as API from '../../utils/ajax';
import * as Actions from './actions';

function* getSignupAsync(action) {
  const { meta } = action;

  try {
    const response = yield call(API.post, SIGNUP, action.payload);
    yield put(Actions.getSignupSuccess(response, action));
  } catch (error) {
    yield put(Actions.getSignupError(error, action));
  }
}

function* getVerifyOtpAsync(action) {
  const { meta } = action;

  try {
    const response = yield call(API.post, VERIFY_OTP, action.payload);
    yield put(Actions.getVerifyOtpSuccess(response, action));
  } catch (error) {
    yield put(Actions.getVerifyOtpError(error, action));
  }
}

function* getUpdateUserAsync(action) {
  try {
    const response = yield call(API.post, UPDATE_USER_API, action.payload);
    yield put(Actions.getUpdateUserSuccess(response, action));
  } catch (error) {
    yield put(Actions.getUpdateUserError(error, action));
  }
}

function* getEditUserAsync(action) {
  try {
    const response = yield call(API.post, GET_EDIT_USER_API, action.payload);
    yield put(Actions.getEditUserSuccess(response, action));
  } catch (error) {
    yield put(Actions.getEditUserError(error, action));
  }
}

function* getUserDetailAsync(action) {
  try {
    const response = yield call(API.get, GET_USER_DETAIL_API, action.payload);
    yield put(Actions.getUserDetailSuccess(response, action));
  } catch (error) {
    yield put(Actions.getUserDetailError(error, action));
  }
}

function* getUploadProfilePicAsync(action) {
  try {
    const response = yield call(
      API.upload,
      '/api/v1/banner/uploadFiles?type=profile',
      action.payload
    );
    yield put(Actions.getUploadProfilePicSuccess(response, action));
  } catch (error) {
    yield put(Actions.getUploadProfilePicError(error, action));
  }
}

function* signupWithEmailAsync(action) {
  try {
    const response = yield call(
      API.post,
      SIGNUP_WITH_EMAIL_API,
      action.payload
    );
    yield put(Actions.signUpWithEmailSuccess(response, action));
  } catch (error) {
    yield put(Actions.signUpWithEmailError(error, action));
  }
}

function* generatePhoneOtpAsync(action) {
  try {
    const response = yield call(
      API.post,
      GENERATE_PHONE_OTP_API,
      action.payload
    );
    yield put(Actions.generatePhoneOtpSuccess(response, action));
  } catch (error) {
    yield put(Actions.generatePhoneOtpError(error, action));
  }
}

function* requestingAccountDeleteAsync(action) {
  try {
    const response = yield call(
      API.post,
      REQUESTING_ACCOUNT_DELETE_API,
      action.payload
    );
    yield put(Actions.requestAccountDeleteSuccess(response, action));
  } catch (error) {
    yield put(Actions.requestAccountDeleteError(error, action));
  }
}

export function* watchSignupSaga() {
  yield takeLatest(GET_SIGNUP, getSignupAsync);
  yield takeLatest(GET_VERIFY_OTP, getVerifyOtpAsync);
  yield takeLatest(GET_UPDATE_USER, getUpdateUserAsync);
  yield takeLatest(GET_EDIT_USER, getEditUserAsync);
  yield takeLatest(GET_USER_DETAIL, getUserDetailAsync);
  yield takeLatest(UPLOAD_PROFILE_PIC, getUploadProfilePicAsync);
  yield takeLatest(SIGNUP_WITH_EMAIL, signupWithEmailAsync);
  yield takeLatest(GENERATE_PHONE_OTP, generatePhoneOtpAsync);
  yield takeLatest(REQUESTING_ACCOUNT_DELETE, requestingAccountDeleteAsync);
}

const signupSaga = [fork(watchSignupSaga)];
export default signupSaga;
