import {
  // ADD_TO_CART,
  // ADD_TO_CART_SUCCESS,
  // ADD_TO_CART_ERROR,
  // GET_CART_ITEM,
  // GET_CART_ITEM_SUCCESS,
  // GET_CART_ITEM_ERROR,
  // ADD_REMOVE_CART_ITEM,
  // ADD_REMOVE_CART_ITEM_SUCCESS,
  // ADD_REMOVE_CART_ITEM_ERROR,
  CHECKOUT_CART,
  CHECKOUT_CART_SUCCESS,
  CHECKOUT_CART_ERROR,
  CHECKOUT_CART_APP,
  CHECKOUT_CART_APP_SUCCESS,
  CHECKOUT_CART_APP_ERROR,
  GET_CLEAR_CART,
  GET_CLEAR_CART_SUCCESS,
  GET_CLEAR_CART_ERROR,
  // GET_OUTLET_CHANGE,
  // GET_OUTLET_CHANGE_ERROR,
  // GET_OUTLET_CHANGE_SUCCESS,
  GET_OUTLET_CHANGE1,
  // UPDATE_CHARITY,
  GET_LEGAL,
  GET_LEGAL_SUCCESS,
  GET_LEGAL_ERROR,
  // GET_OUTLET_CHANGE1_ERROR,
  // GET_OUTLET_CHANGE1_SUCCESS,
  GET_ADDONS_LIST,
  GET_ADDONS_LIST_SUCCESS,
  GET_ADDONS_LIST_ERROR,
  GET_PAYMENT_STATUS,
  GET_PAYMENT_STATUS_ERROR,
  GET_PAYMENT_STATUS_SUCCESS,
  GET_APP_PAYMENT_STATUS,
  GET_APP_PAYMENT_STATUS_SUCCESS,
  GET_APP_PAYMENT_STATUS_ERROR,
  GET_APPLY_COUPON,
  GET_APPLY_COUPON_ERROR,
  GET_APPLY_COUPON_SUCCESS,
  GET_REMOVE_COUPON,
  GET_REMOVE_COUPON_ERROR,
  GET_REMOVE_COUPON_SUCCESS,
  OFFER_ADD_TO_CART,
  OFFER_ADD_TO_CART_SUCCESS,
  OFFER_ADD_TO_CART_ERROR,
  SKD_OFFER_ADD_TO_CART,
  SKD_OFFER_ADD_TO_CART_SUCCESS,
  SKD_OFFER_ADD_TO_CART_ERROR,
  GET_CHARITY_PRODUCT,
  GET_GET_CHARITY_PRODUCT_SUCCESS,
  GET_GET_CHARITY_PRODUCT_ERROR,
  // CART_ADD_TO_CART,
  // CART_ADD_TO_CART_SUCCESS,
  // CART_ADD_TO_CART_ERROR,
  // PDP_ADD_TO_CART,
  // PDP_ADD_TO_CART_SUCCESS,
  // PDP_ADD_TO_CART_ERROR,
  // PLP_ADD_TO_CART,
  // PLP_ADD_TO_CART_SUCCESS,
  // PLP_ADD_TO_CART_ERROR,
  // CART_GET_CART_ITEM,
  // CART_GET_CART_ITEM_SUCCESS,
  // CART_GET_CART_ITEM_ERROR,
  GET_FEEDBACK,
  GET_FEEDBACK_ERROR,
  GET_FEEDBACK_SUCCESS,
  RESEND_OFFER_OTP,
  RESEND_OFFER_OTP_SUCCESS,
  RESEND_OFFER_OTP_ERROR,
  VERIFY_OFFER_OTP,
  VERIFY_OFFER_OTP_SUCCESS,
  VERIFY_OFFER_OTP_ERROR,
  CANCEL_ORDER,
  CANCEL_ORDER_SUCCESS,
  CANCEL_ORDER_ERROR,
  GET_CONTROLLERS,
  GET_CONTROLLERS_SUCCESS,
  GET_CONTROLLERS_ERROR,
  JUSPAY_PAYMENT_METHOD_STATUS,
  JUSPAY_PAYMENT_METHOD_STATUS_SUCCESS,
  JUSPAY_PAYMENT_METHOD_STATUS_ERROR
  // GET_REDIS_CART_ITEM,
  // GET_REDIS_CART_ITEM_SUCCESS,
  // GET_REDIS_CART_ITEM_ERROR
} from '../actionTypes';

const initialState = {
  cart: {},
  clearCart: {},
  outletData: {},
  legalResponse: [],
  isCharityAdded:
    (window &&
      window.sessionStorage &&
      window.sessionStorage.getItem('is_charity')) === 'true' || false
};
export default function CartReducer(state = initialState, action) {
  switch (action.type) {
    // case ADD_TO_CART: {
    //   return {
    //     ...state,
    //     Payload: action.payload,
    //     action: action.type,
    //     addToCart: {},
    //     Error: null,
    //     meta: action.meta
    //   };
    // }
    // case ADD_TO_CART_SUCCESS: {
    //   return {
    //     ...state,
    //     Payload: {},
    //     action: action.type,
    //     addToCart: action.value,
    //     Error: null,
    //     meta: action.meta
    //   };
    // }
    // case ADD_TO_CART_ERROR: {
    //   return {
    //     ...state,
    //     Payload: action.payload,
    //     action: action.type,
    //     addToCart: {},
    //     Error: action.error,
    //     meta: action.meta
    //   };
    // }

    case OFFER_ADD_TO_CART: {
      return {
        ...state,
        Payload: action.payload,
        action: action.type,
        addToCart: {},
        Error: null,
        meta: action.meta
      };
    }
    case OFFER_ADD_TO_CART_SUCCESS: {
      return {
        ...state,
        Payload: {},
        action: action.type,
        addToCart: action.value,
        Error: null,
        meta: action.meta
      };
    }
    case OFFER_ADD_TO_CART_ERROR: {
      return {
        ...state,
        Payload: action.payload,
        action: action.type,
        addToCart: {},
        Error: action.error,
        meta: action.meta
      };
    }

    case SKD_OFFER_ADD_TO_CART: {
      return {
        ...state,
        Payload: action.payload,
        action: action.type,
        addToCart: {},
        Error: null,
        meta: action.meta
      };
    }
    case SKD_OFFER_ADD_TO_CART_SUCCESS: {
      return {
        ...state,
        Payload: {},
        action: action.type,
        addToCart: action.value,
        Error: null,
        meta: action.meta
      };
    }
    case SKD_OFFER_ADD_TO_CART_ERROR: {
      return {
        ...state,
        Payload: action.payload,
        action: action.type,
        addToCart: {},
        Error: action.error,
        meta: action.meta
      };
    }
    case GET_CLEAR_CART: {
      return {
        ...state,
        Payload: action.payload,
        action: action.type,
        clearCart: {},
        meta: action.meta,
        Error: null
      };
    }
    case GET_CLEAR_CART_SUCCESS: {
      return {
        ...state,
        Payload: {},
        action: action.type,
        clearCart: action.value,
        meta: action.meta,
        Error: null
      };
    }
    case GET_CLEAR_CART_ERROR: {
      return {
        ...state,
        Payload: action.payload,
        action: action.type,
        clearCart: {},
        meta: action.meta,
        Error: action.error
      };
    }

    case CHECKOUT_CART: {
      // console.log(action, ' action ');
      return {
        ...state,
        Payload: action.payload,
        action: action.type,
        checkoutCart: {},
        Error: null,
        meta: action.meta
      };
    }
    case CHECKOUT_CART_SUCCESS: {
      return {
        ...state,
        Payload: {},
        action: action.type,
        checkoutCart: action.value,
        Error: null,
        meta: action.meta
      };
    }
    case CHECKOUT_CART_ERROR: {
      return {
        ...state,
        Payload: action.payload,
        action: action.type,
        checkoutCart: {},
        Error: action.error,
        meta: action.meta
      };
    }

    case CHECKOUT_CART_APP: {
      return {
        ...state,
        Payload: action.payload,
        action: action.type,
        checkoutCart: {},
        Error: null,
        meta: action.meta
      };
    }
    case CHECKOUT_CART_APP_SUCCESS: {
      return {
        ...state,
        Payload: {},
        action: action.type,
        checkoutCart: action.value,
        Error: null,
        meta: action.meta
      };
    }
    case CHECKOUT_CART_APP_ERROR: {
      return {
        ...state,
        Payload: action.payload,
        action: action.type,
        checkoutCart: {},
        Error: action.error,
        meta: action.meta
      };
    }

    // case GET_OUTLET_CHANGE: {
    //   return {
    //     ...state,
    //     Payload: action.payload,
    //     action: action.type,
    //     outletChange: {},
    //     Error: null,
    //     meta: action.meta
    //   };
    // }
    // case GET_OUTLET_CHANGE_SUCCESS: {
    //   return {
    //     ...state,
    //     Payload: {},
    //     action: action.type,
    //     outletChange: action.value,
    //     Error: null,
    //     meta: action.meta
    //   };
    // }
    // case GET_OUTLET_CHANGE_ERROR: {
    //   return {
    //     ...state,
    //     Payload: action.payload,
    //     action: action.type,
    //     outletChange: {},
    //     Error: action.error,
    //     meta: action.meta
    //   };
    // }
    case GET_OUTLET_CHANGE1: {
      return {
        ...state,
        Payload: action.payload,
        action: action.type,
        outletData: action.payload,
        Error: null,
        meta: action.meta
      };
    }

    // case UPDATE_CHARITY: {
    //   return {
    //     ...state,
    //     Payload: action.payload,
    //     action: action.type,
    //     isCharityAdded: action.payload,
    //     Error: null,
    //     meta: action.meta
    //   };
    // }

    case GET_ADDONS_LIST: {
      return {
        ...state,
        Payload: action.payload,
        action: action.type,
        addonsList: {},
        Error: null,
        meta: action.meta
      };
    }
    case GET_ADDONS_LIST_SUCCESS: {
      return {
        ...state,
        Payload: {},
        action: action.type,
        addonsList: action.value,
        Error: null,
        meta: action.meta
      };
    }
    case GET_ADDONS_LIST_ERROR: {
      return {
        ...state,
        Payload: action.payload,
        action: action.type,
        addonsList: {},
        Error: action.error,
        meta: action.meta
      };
    }
    case GET_LEGAL: {
      return {
        ...state,
        Payload: action.payload,
        action: action.type,
        legalResponse: [],
        Error: null,
        meta: action.meta
      };
    }
    case GET_LEGAL_SUCCESS: {
      return {
        ...state,
        Payload: {},
        action: action.type,
        legalResponse: action.value,
        Error: null,
        meta: action.meta
      };
    }
    case GET_LEGAL_ERROR: {
      return {
        ...state,
        Payload: action.payload,
        action: action.type,
        legalResponse: [],
        Error: action.error,
        meta: action.meta
      };
    }

    case GET_PAYMENT_STATUS: {
      return {
        ...state,
        Payload: action.payload,
        action: action.type,
        paymentStatus: {},
        Error: null,
        meta: action.meta
      };
    }
    case GET_PAYMENT_STATUS_SUCCESS: {
      return {
        ...state,
        Payload: {},
        action: action.type,
        paymentStatus: action.value,
        Error: null,
        meta: action.meta
      };
    }
    case GET_PAYMENT_STATUS_ERROR: {
      return {
        ...state,
        Payload: action.payload,
        action: action.type,
        paymentStatus: {},
        Error: action.error,
        meta: action.meta
      };
    }

    case GET_APP_PAYMENT_STATUS: {
      return {
        ...state,
        Payload: action.payload,
        action: action.type,
        paymentStatus: {},
        Error: null,
        meta: action.meta
      };
    }
    case GET_APP_PAYMENT_STATUS_SUCCESS: {
      return {
        ...state,
        Payload: {},
        action: action.type,
        paymentStatus: action.value,
        Error: null,
        meta: action.meta
      };
    }
    case GET_APP_PAYMENT_STATUS_ERROR: {
      return {
        ...state,
        Payload: action.payload,
        action: action.type,
        paymentStatus: {},
        Error: action.error,
        meta: action.meta
      };
    }

    case GET_APPLY_COUPON: {
      return {
        ...state,
        Payload: action.payload,
        action: action.type,
        applyCoupon: {},
        Error: null,
        meta: action.meta
      };
    }
    case GET_APPLY_COUPON_SUCCESS: {
      return {
        ...state,
        Payload: {},
        action: action.type,
        applyCoupon: action.value,
        Error: null,
        meta: action.meta
      };
    }
    case GET_APPLY_COUPON_ERROR: {
      return {
        ...state,
        Payload: action.payload,
        action: action.type,
        applyCoupon: {},
        Error: action.error,
        meta: action.meta
      };
    }

    case GET_REMOVE_COUPON: {
      return {
        ...state,
        Payload: action.payload,
        action: action.type,
        removeCoupon: {},
        Error: null,
        meta: action.meta
      };
    }
    case GET_REMOVE_COUPON_SUCCESS: {
      return {
        ...state,
        Payload: {},
        action: action.type,
        removeCoupon: action.value,
        Error: null,
        meta: action.meta
      };
    }
    case GET_REMOVE_COUPON_ERROR: {
      return {
        ...state,
        Payload: action.payload,
        action: action.type,
        removeCoupon: {},
        Error: action.error,
        meta: action.meta
      };
    }

    case GET_CHARITY_PRODUCT: {
      return {
        ...state,
        Payload: action.payload,
        action: action.type,
        charityProduct: null,
        Error: null,
        meta: action.meta
      };
    }
    case GET_GET_CHARITY_PRODUCT_SUCCESS: {
      return {
        ...state,
        Payload: {},
        action: action.type,
        charityProduct: action.value,
        Error: null,
        meta: action.meta
      };
    }
    case GET_GET_CHARITY_PRODUCT_ERROR: {
      return {
        ...state,
        Payload: action.payload,
        action: action.type,
        charityProduct: null,
        Error: action.error,
        meta: action.meta
      };
    }

    case GET_FEEDBACK: {
      return {
        ...state,
        Payload: action.payload,
        action: action.type,
        feedback: null,
        Error: null,
        meta: action.meta
      };
    }
    case GET_FEEDBACK_SUCCESS: {
      return {
        ...state,
        Payload: {},
        action: action.type,
        feedback: action.value,
        Error: null,
        meta: action.meta
      };
    }
    case GET_FEEDBACK_ERROR: {
      return {
        ...state,
        Payload: action.payload,
        action: action.type,
        feedback: null,
        Error: action.error,
        meta: action.meta
      };
    }

    case RESEND_OFFER_OTP: {
      return {
        ...state,
        Payload: action.payload,
        action: action.type,
        resendOfferOtp: null,
        Error: null,
        meta: action.meta
      };
    }
    case RESEND_OFFER_OTP_SUCCESS: {
      return {
        ...state,
        Payload: {},
        action: action.type,
        resendOfferOtp: action.value,
        Error: null,
        meta: action.meta
      };
    }
    case RESEND_OFFER_OTP_ERROR: {
      return {
        ...state,
        Payload: action.payload,
        action: action.type,
        resendOfferOtp: null,
        Error: action.error,
        meta: action.meta
      };
    }

    case VERIFY_OFFER_OTP: {
      return {
        ...state,
        Payload: action.payload,
        action: action.type,
        verifyOfferOtp: null,
        Error: null,
        meta: action.meta
      };
    }
    case VERIFY_OFFER_OTP_SUCCESS: {
      return {
        ...state,
        Payload: {},
        action: action.type,
        verifyOfferOtp: action.value,
        Error: null,
        meta: action.meta
      };
    }
    case VERIFY_OFFER_OTP_ERROR: {
      return {
        ...state,
        Payload: action.payload,
        action: action.type,
        verifyOfferOtp: null,
        Error: action.error,
        meta: action.meta
      };
    }
    case CANCEL_ORDER: {
      return {
        ...state,
        Payload: action.payload,
        action: action.type,
        cancelOrder: {},
        Error: null,
        meta: action.meta
      };
    }
    case CANCEL_ORDER_SUCCESS: {
      return {
        ...state,
        Payload: {},
        action: action.type,
        cancelOrder: action.value,
        Error: null,
        meta: action.meta
      };
    }
    case CANCEL_ORDER_ERROR: {
      return {
        ...state,
        Payload: action.payload,
        action: action.type,
        cancelOrder: {},
        Error: action.error,
        meta: action.meta
      };
    }

    case GET_CONTROLLERS: {
      return {
        ...state,
        Payload: action.payload,
        action: action.type,
        controllers: null,
        Error: null,
        meta: action.meta
      };
    }
    case GET_CONTROLLERS_SUCCESS: {
      return {
        ...state,
        Payload: {},
        action: action.type,
        controllers: action.value,
        Error: null,
        meta: action.meta
      };
    }
    case GET_CONTROLLERS_ERROR: {
      return {
        ...state,
        Payload: action.payload,
        action: action.type,
        controllers: null,
        Error: action.error,
        meta: action.meta
      };
    }

    case JUSPAY_PAYMENT_METHOD_STATUS: {
      return {
        ...state,
        Payload: action.payload,
        action: action.type,
        paymentMethod: {},
        Error: null,
        meta: action.meta
      };
    }
    case JUSPAY_PAYMENT_METHOD_STATUS_SUCCESS: {
      return {
        ...state,
        Payload: {},
        action: action.type,
        paymentMethod: action.value,
        Error: null,
        meta: action.meta
      };
    }
    case JUSPAY_PAYMENT_METHOD_STATUS_ERROR: {
      return {
        ...state,
        Payload: action.payload,
        action: action.type,
        paymentMethod: {},
        Error: action.error,
        meta: action.meta
      };
    }

    default: {
      return {
        ...state
      };
    }
  }
}
