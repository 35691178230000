import { put, call, fork, takeLatest } from 'redux-saga/effects';
import {
  GET_ADDRESS_LIST,
  ADD_ADDRESS,
  DELETE_ADDRESS,
  GET_OUTLET_LIST
} from '../actionTypes';
import {
  GET_ADDRESS_LIST_API,
  ADD_ADDRESS_API,
  DELETE_ADDRESS_API,
  GET_OUTLETS_API
} from '../../constants/api-constants';
import * as API from '../../utils/ajax';
import * as Actions from './actions';

function* getAddressListAsync(action) {
  try {
    // console.log("ckkkk",action);
    const response = yield call(API.get, GET_ADDRESS_LIST_API, action.payload);
    yield put(Actions.getAddressListSuccess(response, action));
  } catch (error) {
    yield put(Actions.getAddressListError(error, action));
  }
}

function* addAddressAsync(action) {
  try {
    const response = yield call(API.post, ADD_ADDRESS_API, action.payload);
    yield put(Actions.addAddressSuccess(response, action));
  } catch (error) {
    yield put(Actions.addAddressError(error, action));
  }
}

function* getDeleteAddressAsync(action) {
  try {
    const response = yield call(API.post, DELETE_ADDRESS_API, action.payload);
    yield put(Actions.getDeleteAddressSuccess(response, action));
  } catch (error) {
    yield put(Actions.getDeleteAddressError(error, action));
  }
}

function* getOutletAsync(action) {
  try {
    const response = yield call(API.post, GET_OUTLETS_API, action.payload);
    yield put(Actions.getOutletSuccess(response, action));
  } catch (error) {
    yield put(Actions.getOutletError(error, action));
  }
}

export function* watchCartSaga() {
  yield takeLatest(GET_ADDRESS_LIST, getAddressListAsync);
  yield takeLatest(ADD_ADDRESS, addAddressAsync);
  yield takeLatest(DELETE_ADDRESS, getDeleteAddressAsync);
  yield takeLatest(GET_OUTLET_LIST, getOutletAsync);
}
const addressSaga = [fork(watchCartSaga)];
export default addressSaga;
