import React from 'react';
import './index.scss';
import { LoginSocialFacebook, LoginSocialGoogle } from 'reactjs-social-login';
// import GoogleLogin from 'react-google-login';
import { GoogleLogin } from '@react-oauth/google';
import Fb from '../../../assets/images/facebook-app.svg';
import Google from '../../../assets/images/google_icon.png';

class SocialButton extends React.Component {
  render() {
    const { onFacebookLogin, responseGoogle } = this.props;

    return (
      <>
        <div className="social">
          <span className="social_line" />
          <span className="social_text"> or </span>
        </div>
        <p className="social_p"> Login Using</p>
        <div
          className="social_login"
          style={{
            gap: '2rem'
          }}
        >
          {/* <LoginSocialFacebook
            appId="3697007223698807"
            onResolve={data => onFacebookLogin(data)}
            onReject={() => {}}
          >
            <div className="social_loginData">
              <img src={Fb} height="20" width="18" />
              <p className="social_name">Facebook</p>
            </div>
          </LoginSocialFacebook> */}
          <GoogleLogin
            // buttonText="Google"
            // onSuccess={data => {
            //   responseGoogle(data);
            // }}
            // onFailure={() => responseGoogle()}
            // // cookiePolicy={'single_host_origin'}
            // render={renderProps => (
            //   <div className="social_loginData" onClick={renderProps.onClick}>
            //     <img src={Google} height="18" width="18" />
            //     <p className="social_name">Google</p>
            //   </div>
            // )}
            onSuccess={credentialResponse => {
              console.log(credentialResponse);
            }}
            onError={() => {
              console.log('Login Failed');
            }}
          />
        </div>
      </>
    );
  }
}

export default SocialButton;
