/* eslint-disable no-undef */
import './index.scss';

import React, { Component } from 'react';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import moment from 'moment';

import AccountSubSecTitle from '../../components/accout-subsec-title';
import InputLabelFloat from '../../components/input-label-float';
import CustomDatePicker from '../../components/date-picker';
import Button from '../../components/button';
import ProfilePhotoEdit from '../../components/profile-photo-edit';
import Modal from '../../containers/modal';
import Loader from '../../components/loader-backdrop';
import { ROUTES as _routes } from '../../constants/app-constants';
// import InstagramAppIcon from '../../../assets/images/instagram-app.jpg';
// import FacebookAppIcon from '../../../assets/images/facebook-app.svg';
// import TwitterAppIcon from '../../../assets/images/twitter-app.svg';
import TickMarkIcon from '../../../assets/images/tickmark.svg';

import {
  getEditUser,
  getUserDetail,
  getUploadProfilePic
} from '../../burger-king-lib/redux/login/actions';

import { getUserEditImage, getEditImage } from '../../utils/imageChanges';

import { EmailValidation, NameValidation } from '../../utils/validation';
import { getEditUserPayload } from '../../payload-handling/login';

class Profile extends Component {
  constructor(props) {
    super(props);
    this.state = {
      name: '',
      phoneNumber: '',
      emailId: '',
      dob: '',
      profilePic: '',
      gender: '',
      currentFieldFocus: 'name',
      showEditProfileModal: false,
      showLoader: false,
      isEmailVerified: '',
      validation: {
        emailId: false,
        name: false
      }
    };
  }

  componentDidMount() {
    const { loginData } = this.props;
    if (loginData.userDetail && loginData.userDetail.content) {
      this.setUserDetail(loginData.userDetail.content);
    }
  }

  componentDidUpdate(prevProps) {
    const { loginData } = this.props;
    if (
      loginData &&
      prevProps.loginData !== loginData &&
      prevProps.loginData.editUser !== loginData.editUser
    ) {
      if (
        loginData.editUser.message &&
        loginData.editUser.error &&
        !loginData.editUser.content
      ) {
        alert(loginData.editUser.message);
      } else if (
        loginData.editUser.message &&
        !loginData.editUser.error &&
        loginData.editUser.content
      ) {
        const { editUserSuccessHandler, getUserDetailData } = this;
        editUserSuccessHandler();
        getUserDetailData();
      }
      if (loginData.editUser.message) this.hideLoader();
    }
    if (
      loginData &&
      prevProps.loginData !== loginData &&
      prevProps.loginData.userDetail !== loginData.userDetail
    ) {
      if (loginData.userDetail.message && loginData.userDetail.error)
        alert(loginData.userDetail.message);
      else if (loginData.userDetail.content) {
        const { setUserDetail } = this;
        setUserDetail(loginData.userDetail.content);
      }
    }

    if (
      loginData &&
      prevProps.loginData !== loginData &&
      prevProps.loginData.uploadProfilePic !== loginData.uploadProfilePic
    ) {
      if (
        loginData.uploadProfilePic.message &&
        loginData.uploadProfilePic.error
      )
        alert(loginData.uploadProfilePic.message);
      else if (loginData.uploadProfilePic.content) {
        const { setProfilePicUrl, hideProfileEditModal } = this;
        setProfilePicUrl(loginData.uploadProfilePic.content);
        hideProfileEditModal();
      }
    }
  }

  getUserDetailData = () => {
    const { getUserDetailData } = this.props;
    getUserDetailData();
  };

  getEditUserData = (name, dob, emailId, phoneNumber, image_url, gender) => {
    const { getEditUserData } = this.props;
    const payload = getEditUserPayload(
      name,
      dob,
      emailId,
      phoneNumber,
      image_url,
      gender
    );
    getEditUserData(payload);
    const script = document.createElement('script');
    script.src = '//cdnt.netcoresmartech.com/smartechclient.js';
    script.onload = () => {
      smartech(
        'contact',
        6,
        {
          'pk^mobile': phoneNumber,
          NAME: name,
          DOB: dob ? moment(dob, 'DD/MM/YYYY').format('DD-MM-YYYY') : '',
          email: emailId,
          GENDER: gender,
          PROFILE_PIC: image_url
        },
        () => {
          smartech('identify', `${phoneNumber}`);
          smartech('dispatch', 'af_edit_profile', {
            NAME: name,
            DOB: dob ? moment(dob, 'DD/MM/YYYY').format('DD-MM-YYYY') : '',
            email: emailId,
            mobile: phoneNumber,
            GENDER: gender,
            PROFILE_PIC: image_url
          });
        }
      );
    };
    document.head.appendChild(script);
  };

  setUserDetail = data => {
    const [FirEl] = data;
    const {
      dob,
      email,
      phone_no,
      name,
      profile_pic,
      is_email_verified,
      gender
    } = FirEl;
    this.setState({
      dob: dob || '',
      emailId: email,
      phoneNumber: phone_no,
      name,
      profilePic: profile_pic,
      isEmailVerified: is_email_verified,
      gender
    });
  };

  setProfilePicUrl = data => {
    if (data && data.image_url) {
      this.setState({ profilePic: data.image_url });
    }
  };

  hideLoader = () => {
    this.setState({ showLoader: false });
  };

  editUserSuccessHandler = () => {
    const { editUserSuccessHandler } = this.props;
    editUserSuccessHandler();
    if (window.innerWidth < 1023) {
      this.goBackClick();
    }
  };

  goBackClick = () => {
    const { history } = this.props;
    history.push(_routes.HOME);
  };

  inputOnChange = (id, value) => {
    this.setState({ [id]: value });
  };

  inputOnFocus = id => {
    this.setState({ currentFieldFocus: id });
  };

  goBackOnClick = () => {
    const { history } = this.props;
    history.go(-1);
  };

  onClickValidation = () => {
    const { validation } = this.state;
    const validationCopy = { ...validation };
    const { emailId, name, dob } = this.state;
    validationCopy.emailId = !(emailId ? EmailValidation(emailId) : true);
    validationCopy.name = !NameValidation(name);
    validationCopy.dob = !(dob ? moment(dob, 'DD/MM/YYYY').isValid() : true);

    this.setState({ validation: validationCopy });
    return validationCopy;
  };

  saveOnClickHandler = () => {
    const { getEditUserData } = this;
    const { name, dob, emailId, phoneNumber, profilePic, gender } = this.state;
    const validation = this.onClickValidation();
    if (
      Object.keys(validation).every(k => {
        this.setState({ currentFieldFocus: k });
        if (validation[k]) this.validationAlert(k);
        return !validation[k];
      })
    ) {
      this.setState({ showLoader: true });
      getEditUserData(name, dob, emailId, phoneNumber, profilePic, gender);
    }
  };

  validationAlert = key => {
    if (key === 'emailId') {
      alert('Please enter a valid email');
    }
    if (key === 'name') {
      alert('Please enter a valid name');
    }
    if (key === 'dob') {
      alert('Please enter valid date');
    }
  };

  showEditProfile = () => {
    this.setState({ showEditProfileModal: true });
  };

  hideProfileEditModal = () => {
    this.setState({ showEditProfileModal: false });
  };

  selectFromGalleryOnChange = event => {
    const { target } = event;
    const { files } = target;
    const { hideProfileEditModal } = this;
    if (files && files.length) {
      const selected = files[0];
      if (selected.type.startsWith('image/')) {
        const { getUploadProfilePicData } = this.props;
        const formData = new FormData();
        formData.append('file', files[0]);
        getUploadProfilePicData(formData);
      } else {
        alert('Only images are allowed.');
      }
    }
    hideProfileEditModal();
  };

  removeProfilePicHandler = () => {
    const { getEditUserData, hideProfileEditModal } = this;
    const { name, dob, emailId, phoneNumber, gender } = this.state;
    this.setState({ showLoader: true });
    getEditUserData(name, dob, emailId, phoneNumber, '', gender);
    hideProfileEditModal();
  };

  render() {
    const {
      name,
      currentFieldFocus,
      phoneNumber,
      emailId,
      dob,
      showEditProfileModal,
      showLoader,
      profilePic,
      isEmailVerified,
      gender
    } = this.state;
    const { theme, onCloseHandler } = this.props;
    const {
      inputOnChange,
      inputOnFocus,
      goBackOnClick,
      saveOnClickHandler,
      hideProfileEditModal,
      selectFromGalleryOnChange,
      removeProfilePicHandler
    } = this;

    const ProfilePhotoEditDom = (
      <ProfilePhotoEdit
        selectFromGalleryOnChange={selectFromGalleryOnChange}
        removeProfilePicHandler={removeProfilePicHandler}
        profilePic={profilePic}
      />
    );

    return (
      <>
        <Modal
          onCloseHandler={onCloseHandler}
          isMobileFullLayout
          hideClose={window.innerWidth < 1024}
          repeatCustomization={
            window.innerWidth >= 1024 && showEditProfileModal
              ? ProfilePhotoEditDom
              : ''
          }
          onCloseRepeatHandler={hideProfileEditModal}
        >
          <div className="profile-wrapper">
            <AccountSubSecTitle
              theme={theme}
              title="Edit Profile"
              goBackOnClick={goBackOnClick}
            />
            <div className={`profile ${theme}`}>
              <div className="profile__image-wrapper">
                <div
                  className="profile__image u_cursor_pointer"
                  onClick={() => this.showEditProfile()}
                  role="presentation"
                >
                  <img
                    className="profile__user-img"
                    src={profilePic || getUserEditImage(theme)}
                    alt="profile"
                  />
                  <img
                    className="profile__edit-img"
                    src={getEditImage(theme)}
                    alt="edit"
                  />
                </div>
              </div>

              <InputLabelFloat
                customClass="profile__input"
                label="Name"
                theme={theme}
                id="name"
                onFocus={inputOnFocus}
                onChange={inputOnChange}
                value={name}
                currentFieldFocus={currentFieldFocus}
              />
              <InputLabelFloat
                customClass="profile__input"
                label="Mobile Number"
                id="phoneNumber"
                theme={theme}
                onFocus={inputOnFocus}
                onChange={inputOnChange}
                value={phoneNumber}
                currentFieldFocus={currentFieldFocus}
                disabled
              />
              <div className="profile__email">
                <InputLabelFloat
                  customClass="profile__input"
                  label="Email ID"
                  theme={theme}
                  id="emailId"
                  onFocus={inputOnFocus}
                  onChange={inputOnChange}
                  value={emailId}
                  currentFieldFocus={currentFieldFocus}
                  // validationChange={validationChangeHandler}
                  // validation={validation.emailId}
                  disabled={isEmailVerified}
                />
                {isEmailVerified ? (
                  <div className="profile__email_verified">
                    <div className="profile__email_image">
                      {' '}
                      <img
                        src={TickMarkIcon}
                        alt="tick mark"
                        className="profile__email_img"
                      />{' '}
                    </div>
                  </div>
                ) : (
                  ''
                )}
              </div>

              <CustomDatePicker
                id="dob"
                label="Date of Birth"
                required
                theme={theme}
                value={dob}
                onChange={inputOnChange}
                // validation={validation.date_of_birth}
                maxDate={new Date()}
                dateFormat="dd/MM/yyyy"
                // validationChange={this.validationChangeHandler}
                onFocus={this.inputOnFocus}
                currentFieldFocus={currentFieldFocus}
              />

              <div className="profile__gender">
                <p className="profile__gender-title"> Gender :</p>
                {/* <div className="profile__gender-options"> */}
                <div
                  className="profile__gender-content"
                  onClick={() => inputOnChange('gender', 'male')}
                  role="presentation"
                >
                  <div
                    className={`profile__gender-radio ${theme} ${
                      gender === 'male'
                        ? `profile__gender-radio_active ${theme}`
                        : ''
                    }`}
                  />
                  <p className="profile__gender-value">Male</p>
                </div>
                <div
                  className="profile__gender-content"
                  onClick={() => inputOnChange('gender', 'female')}
                  role="presentation"
                >
                  <div
                    className={`profile__gender-radio  ${theme} ${
                      gender === 'female'
                        ? `profile__gender-radio_active ${theme}`
                        : ''
                    }`}
                  />
                  <p className="profile__gender-value">Female</p>
                </div>
                <div
                  className="profile__gender-content"
                  onClick={() => inputOnChange('gender', 'others')}
                  role="presentation"
                >
                  <div
                    className={`profile__gender-radio  ${theme} ${
                      gender === 'others'
                        ? `profile__gender-radio_active ${theme}`
                        : ''
                    }`}
                  />
                  <p className="profile__gender-value">Others</p>
                </div>
                {/* </div> */}
              </div>

              {/* <div className="profile__link">
                <p className="profile__link-text"> Link Social Accounts </p>
                <ul className="u_display_flex u_list_style_none">
                  <li className="profile__link-item">
                    <img src={InstagramAppIcon} alt="instagram" />
                  </li>
                  <li className="profile__link-item">
                    <img src={TwitterAppIcon} alt="twitter" />
                  </li>
                  <li className="profile__link-item">
                    <img src={FacebookAppIcon} alt="facebook" />
                  </li>
                </ul>
                <p className="profile__link-earn-text">Earn 50 BK Crowns when you link a social account.</p>
              </div> */}

              <Button
                content="Save changes"
                disabled={!name}
                theme={theme}
                onClick={saveOnClickHandler}
                type="block"
                size="is-large"
                className="profile__button"
              />
            </div>
            {showLoader ? <Loader theme={theme} /> : ''}
          </div>
        </Modal>
        {showEditProfileModal && window.innerWidth < 1024 ? (
          <Modal onCloseHandler={hideProfileEditModal}>
            {ProfilePhotoEditDom}
          </Modal>
        ) : (
          ''
        )}
      </>
    );
  }
}

const mapStateToProps = state => {
  return {
    loginData: state.login
  };
};

const mapDispatchToProps = dispatch => {
  return {
    getEditUserData: payloadData => dispatch(getEditUser(payloadData)),
    getUserDetailData: payloadData => dispatch(getUserDetail(payloadData)),
    getUploadProfilePicData: payloadData =>
      dispatch(getUploadProfilePic(payloadData))
  };
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(Profile)
);
